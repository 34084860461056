<template>
  <div>
    <div v-if="!isVisiblePreOrderHistory">
      <VueLineTabs @selectedTab="setActiveTab">
        <VueLineTab
          v-for="(item, index) in tabItems"
          :selected="activeTabIndex === index"
          :key="index"
          :name="item"
          :id="index"
        >
          <div style="display: flex; flex-direction: column;">
            <DaysTable :activeTabIndex="activeTabIndex" />
            <BrandButton
              style="border: 1px linear-gradient(315deg, #d20051 0% #ffa300 100%), solid; border-radius: 6px; width: 80%; height: 50px; margin-top: 70px; align-self: center;"
              :outlined="true"
              @click="isVisiblePreOrderHistory = !isVisiblePreOrderHistory"
            >
              <VueIcon
                class="point-detail"
                :iconName="icons.iconPointDetail.name"
                :width="icons.iconPointDetail.width"
                :height="icons.iconPointDetail.height"
              />
              <VueText style="color: #E5472D;" :isSingleLine="true" sizeLevel="6" weightLevel="4"
                >Ön Sipariş Geçmişi</VueText
              ></BrandButton
            >
          </div>
        </VueLineTab>
      </VueLineTabs>
    </div>
    <PreOrderHistoryTable v-else />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import VueLineTabs from '@/components/shared/VueTabs/VueLineTabs.vue';
import VueLineTab from '@/components/shared/VueTab/VueLineTab.vue';
import DaysTable from '@/components/brand/DaysTable/DaysTable.vue';
import PreOrderHistoryTable from './PreOrderHistoryTable.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon';

export default {
  components: {
    VueLineTabs,
    VueLineTab,
    DaysTable,
    BrandButton,
    VueText,
    VueIcon,
    PreOrderHistoryTable,
  },
  setup() {
    const activeTabIndex = ref(0);
    const isVisiblePreOrderHistory = ref(false);

    const tabItems = ref(['Ziyaret Günlerim', 'Ön Sipariş Günlerim']);

    const setActiveTab = index => {
      activeTabIndex.value = index;
    };

    const icons = computed(() => {
      return ICON_VARIABLES;
    });

    return {
      isVisiblePreOrderHistory,
      activeTabIndex,
      setActiveTab,
      tabItems,
      icons,
    };
  },
};
</script>
<style scoped lang="scss">
.point-detail {
  margin-right: palette-space-level(10);
  margin-top: palette-space-level(15);
}
</style>
